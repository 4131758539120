<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >lease guaranty
            </label>
          </div>
        </div>
        <div>
          <div class="form-row w-1/3">
            <TextField
              label="Last date for Guarantor renewal"
              name="renewalDate"
              type="date"
            />
          </div>
          <div class="h-4"></div>

          <div class="text-title form-row">
            Length of Obligations. Obligations as Guarantor will continue for
            (check one):
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="obligationInitial" />
            <div class="text-title">only the initial Lease term</div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="obligationAll" />
            <div class="text-title">
              all Lease terms and renewals, including automatic renewals,
              amendments, and modifications entered into by the date listed as
              the last date for Guarantor renewal.
            </div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import TextField from "@/components/form/TextField";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    FinalForm,
    TextField,
    ModalFooter,
    CheckboxInputSmall,
  },
  data() {
    return {
      renewalDate: "",
      obligationInitial: "",
      obligationAll: "",
      footer: {
        primaryButton: "save",
      },
      loading: false,
    };
  },
  computed: {
    initialValues() {
      return {
        renewalDate: "",
        obligationInitial: "",
        obligationAll: "",
      };
    },
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
